.terms-text {
  text-align: justify;
  margin: 1rem 1rem 1rem 0;
}

#terms-tabs {
  .nav-link {
    padding: 17px 32px;
    font-weight: 700;

    &.active {
      background-color: $primary;
      color: white;
    }
  }
}

.terms-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.terms-section {
  padding: 12px 0;
  font-size: 16px;
}

/*********** TERMS OF SERVICE AGREEMENT - Modal ***********/
.terms-modal {
  @media(min-width: 1024px) {
    max-width: 800px;
  }

  &-header {
    display: flex;
    text-align: center;
    padding: 0 16px;
  }

  &-title {
    color: #2A3042;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    width: 100%;
  }

  &-content {
    height: 500px;
    border-radius: 16px;
    border: 1px solid #CED4DA;
    padding: 16px 5px 0 16px;

    .terms-scroll-content {
      height: 100%;
      overflow-y: scroll;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    p {
      font-size: 13px;
      line-height: 22px;
      color: #2C3746;
    }
  }

  &-content-small {
    height: 250px;
    border-radius: 16px;
    border: 1px solid #CED4DA;
    padding: 16px 5px 0 16px;

    .terms-scroll-content {
      height: 100%;
      overflow-y: scroll;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    p {
      font-size: 13px;
      line-height: 22px;
      color: #2C3746;
    }
  }
}