/****************** GLBA Banner ******************/
.glba-banner {
  background: #EEF0FD;
  border-radius: 4px;
  padding: 0 14px;

  &-title {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 15px;
    color: #495057;
  }

  &-description {
    font-family: Poppins;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 15px;
    color: #74788D;
  }
}

/****************** GLBA Assessment View Card ******************/
.glba-assessment-card {
   color: #495057;

  .column-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    color: #222;
  }

  .column-question {
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .column-answer,
  .column-title {
    border-bottom: 1px solid #EFF2F7;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

    .right-border {
      border-right: 1px solid #EFF2F7;
    }
}

/****************** GLBA Assessment Form ******************/
.glba-assessment-form {
  .form-check-label {
    font-weight: 400;
  }

  .card-section {
    min-height: 350px;
  }
}