.table.new-uploaded-docs {

  tr.deleting {
    text-decoration: line-through;
    opacity: 0.5;
  }
}

// used to set a breakpoint for flex responsiveness
.credential-detail-col {
  min-width: 300px;
}

.warning {
  background-color: #F6BCBC;
  color: #E02020;
  border-radius: 4px;
}

.terms-btn {
  width: 167px;
}

// Out of office box row
.out-of-office-box {
  border: 1px solid #CED4DA;
  background: #F8F9FA;
  border-radius: 4px;
  padding: 14px 10px;
}